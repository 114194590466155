import React, { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import { Box, Container, makeStyles } from "@material-ui/core";
import { t } from "@lingui/macro";
import BookingStatePicker from "./BookingStatePicker";
import SearchBoxFilter from "../../search-box-filter";
import theme from "../../../theme";
import { useNavigate } from "../../../hooks/navigation";
import Menu from "./Menu";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    height: "100%",
    flex: "1 1",
    background: "#f8f8f8",
    borderRadius: "12px",
    padding: theme.spacing(4),
  },
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "2 1",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    gap: theme.spacing(2),
    minWidth: "190px",
  },
  input: {
    flex: "1 1",
    minWidth: 0,
    width: "100%",
  },
  boxClasses: {
    width: "100%",
    marginLeft: 0,
    display: "flex",
    "& > .MuiFormControl-root": {
      width: "100%",
    },
  },
}));

const Index = ({
  consults = [],
  bookingId = 0,
  status = "initial",
  initialSearchValue = "",
  initialStateValue = "",
  hasMoreConsults,
  onChangeBookingStatus = () => {},
  onLoadMoreConsults = () => {},
  onSearchBookings = () => {},
}) => {
  const classes = useStyles({ ...theme });
  const { i18n } = useLingui();
  const { navigate } = useNavigate();
  const [listHeight, setListHeight] = useState();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedBookingState, setSelectedBookingState] = useState(initialStateValue);

  const cardClickHandler = (id) => {
    localStorage.setItem("TenantLastBookingSeen", id ? `${selectedBookingState}/chat/${id}` : "");
  };

  const BookingStateHandler = ({ target }) => {
    localStorage.setItem("TenantLastBookingState", target.value);
    setIsFirstRender(true);
    setSelectedBookingState(target.value);
    onChangeBookingStatus(target.value);
    cardClickHandler(null);
    navigate(`/tenant/${target.value}/chat/`);
  };

  const resizeChat = () => {
    const box = document.getElementById("side-menu-list");
    if (box) {
      const height = window.innerHeight - box.offsetTop - 80;
      setListHeight(height);
      if (box.scrollTop === 0 || box.scrollTop - box.scrollHeight < 50) {
        box.scrollTop = box.scrollHeight;
      }
    }
  };

  const handleSearchBookings = (value) => {
    setIsFirstRender(true);
    onSearchBookings(value);
    cardClickHandler(null);
    navigate(`/tenant/${selectedBookingState}/chat/`);
  };

  useEffect(() => {
    if (selectedBookingState && status === "success" && isFirstRender && !bookingId) {
      setIsFirstRender(false);
      if (consults.length) {
        cardClickHandler(consults[0].id);
        navigate(`/tenant/${selectedBookingState}/chat/${consults[0].id}`);
      }
    }
  }, [selectedBookingState, consults, isFirstRender]);

  useEffect(() => {
    onChangeBookingStatus(initialStateValue);
    window.addEventListener("resize", resizeChat);
    resizeChat();
    return () => {
      window.removeEventListener("resize", resizeChat);
    };
  }, []);

  return (
    <Container className={classes.main}>
      <BookingStatePicker BookingState={selectedBookingState} OnChangeBookingState={BookingStateHandler} />
      <Box className={classes.menuContainer}>
        <SearchBoxFilter
          placeholder={i18n._(t`Buscar`)}
          initialSearch={initialSearchValue}
          onSearch={handleSearchBookings}
          inputClasses={classes.input}
          boxClasses={classes.boxClasses}
        />
        <Menu
          listHeight={listHeight}
          consults={consults}
          status={status}
          onClickCard={cardClickHandler}
          bookingState={selectedBookingState}
          bookingId={bookingId}
          hasMoreConsults={hasMoreConsults}
          onLoadMore={() => onLoadMoreConsults()}
        />
      </Box>
    </Container>
  );
};

export default Index;
