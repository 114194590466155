import React from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import Link from "../../link";
import ConsultCard from "../consult-card";
import { isBefore } from "../../../utils/dateUtils";

export default ({
  listHeight,
  consults,
  status,
  onClickCard,
  bookingState,
  bookingId,
  hasMoreConsults,
  onLoadMore,
}) => {
  return (
    <Box
      mt={1}
      height={listHeight}
      style={{ display: "flex", flexDirection: "column", overflowY: "auto", width: "100%" }}
    >
      {status !== "loading" &&
        (consults.length ? (
          consults.map((c, index) => (
            <Link
              onClick={() => onClickCard(c.id)}
              key={`${c.bookingId}-${index}`}
              to={`/tenant/${bookingState}/chat/${c.id}`}
              style={{ textDecoration: "none", display: "flex", width: "100%" }}
            >
              <ConsultCard
                isSelected={bookingId === c.id}
                bookingId={c.id}
                title={c.space ? c.space.title : c.project_name}
                price={c.price}
                paymentPlan={c.payment_plan}
                showPrices={c.show_prices_info}
                periodPrice={c.periodPrice}
                country={c.asset?.city?.country?.name}
                asset={c.asset?.name}
                start={c.start}
                end={c.end}
                bookingState={c.status}
                image={c.spaces[0]?.attachments[0]?.url ? c.spaces[0]?.attachments[0]?.url : c.asset?.thumb_url}
                parentId={c.parent_id}
                hasNotification={c.user_last_seen ? isBefore(c.user_last_seen, c.updated_at) : true}
                confirmedAt={c.confirmed_at}
              />
            </Link>
          ))
        ) : (
          <Box mt={3} mb={3} display="flex" justifyContent="center">
            <Typography variant="h6">
              <Trans>No hay consultas para mostrar</Trans>
            </Typography>
          </Box>
        ))}
      {status === "loading" && (
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {hasMoreConsults && status !== "loading" && (
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <Button onClick={onLoadMore} variant="contained" color="primary">
            <Trans>Cargar más</Trans>
          </Button>
        </Box>
      )}
    </Box>
  );
};
