import { Box, Button, Container, Typography, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Alert from "@material-ui/lab/Alert";
import numbro from "numbro";
import { MAX_MB_ATTACHMENTS } from "../../redux/reducers";
import OnePageMessage from "../one-page-message";
import SpaceInfoPaper from "../../containers/space-info-paper";
import Select from "../select";
import TrashIcon from "../../icons/trash-icon";
import FileUpload from "../file-upload";
import LoadingBar from "../loading-bar";

// theme
import theme from "../../theme";
import Link from "../link";
import BackLongIcon from "../../icons/back-long-icon";
import TenantBookingInvoiesModal from "../../containers/tenant-booking-invoies-modal";
import DownloadIcon from "../../icons/download-icon";
import { removeAWSSpecialCharacters } from "../../utils";
import { formatDate } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 800,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      flexDirection: "column",
    },
  },
  paymentInput: {
    border: theme.borders["3"],
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    width: "100%",
    height: "36px",
    [theme.breakpoints.down("xs")]: {},
    "& .MuiInputBase-input": {
      padding: 0,
    },
  },
  proformaButton: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  customButton: {
    border: theme.borders[1],
    padding: theme.spacing(1 / 2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    cursor: "pointer",
  },
  customLink: {
    textDecoratiom: "underline",
  },
  divider: {
    backgroundColor: theme.palette.black,
    height: 1,
  },
  // TODO: most of things
  uploadSection: {
    display: "flex",
    border: theme.borders[11],
    minHeight: "125px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  uploadPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  uploadSuccessFile: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.success.main,
    width: "95%",
    height: 40,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: "pointer",
  },
  confirmButton: {
    width: 100,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fileName: {
    flex: 5,
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginRight: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileLink: {
    textDecoration: "none",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function PayConsultation({
  booking,
  bookingId,
  status,
  invoices = [],
  onPay,
  payStatus,
  onFinish,
  onError,
  invoiceId,
}) {
  const classes = useStyles(theme);

  const [paymentMethod, setPaymentMethod] = useState("Transferencia bancaria");
  const [uploadStep, setUploadStep] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("initial");
  const [uploadedFile, setUploadedFile] = useState({});
  const [fileName, setFileName] = useState("");

  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleGoToUpload = () => {
    setUploadStep(true);
  };

  const handleRemoveUploadedFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileName("");
    setUploadStatus("initial");
  };

  const handleUploadComplete = (url, fileType, file) => {
    setUploadedFile({ file, url: encodeURI(url) });
    setUploadStatus("success");
  };

  const handlePayment = () => {
    onPay();
  };

  const handleBack = () => {
    setUploadStep(false);
    setUploadStatus("initial");
    onError();
  };

  const { i18n } = useLingui();

  const uploadId = "chat-image-upload";

  const invoice = invoices.find((i) => i.id === Number(invoiceId));

  return (
    <>
      <Container className={classes.container}>
        <Box flex={1}>
          <Link to={`/tenant/inprogress/chat/${bookingId}`} style={{ display: "flex" }}>
            <BackLongIcon />
            <Typography
              variant="subtitle1"
              style={{ display: "flex", alignItems: "center", paddingLeft: theme.spacing(1) }}
            >
              <Trans>VOLVER</Trans>
            </Typography>
          </Link>
          {!invoice?.payment && !uploadStep && (
            <Box display="flex" flexDirection="row" pb={4} mt={4} justifyContent="center" width={["100%", 400]}>
              <Box display="flex" width="100%" flexDirection="column" flex={1} pr={5}>
                <Typography variant="h4" color="textPrimary">
                  <Trans>ESCOGE MÉTODO DE PAGO</Trans>
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2} mb={2}>
                  {invoice ? (
                    <>
                      <Box>
                        <Typography variant="subtitle1" color="textPrimary">
                          <Trans>Total a Pagar</Trans> {": "}
                          {numbro(parseFloat(invoice.total)).formatCurrency()}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Typography variant="subtitle1" color="textPrimary">
                          <Trans>Fecha de la factura</Trans> {": "}
                          {formatDate(invoice.date, "DD/MM/YYYY", false)}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Alert severity="warning">
                      <Trans>No es posible continuar con el pago de la factura actual.</Trans>
                    </Alert>
                  )}
                </Box>
                <Divider className={classes.divider} orientation="horizontal" flexItem />
                <Box mb={1} mt={2}>
                  <Typography variant="subtitle1" color="textPrimary">
                    <Trans>Medio de pago</Trans>
                  </Typography>
                </Box>
                <Box>
                  <Select
                    id="standard-select-currency"
                    label=""
                    className={classes.paymentInput}
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <MenuItem key="Transferencia bancaria" value="Transferencia bancaria">
                      <Trans>Transferencia bancaria</Trans>
                    </MenuItem>
                    <MenuItem key="Tarjeta de crédito" value="Tarjeta de crédito" disabled>
                      <Trans>Tarjeta de crédito</Trans>
                    </MenuItem>
                    <MenuItem key="Paypal" value="Paypal" disabled>
                      <Trans>Paypal</Trans>
                    </MenuItem>
                  </Select>
                  <Box mt={3}>
                    <Button
                      disabled={!invoice}
                      onClick={handleGoToUpload}
                      size="large"
                      variant="contained"
                      color="primary"
                    >
                      <Trans>CONTINUAR</Trans>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {!invoice?.payment &&
            uploadStep &&
            payStatus !== "success" &&
            (payStatus !== "error" ? (
              <Box display="flex" flexDirection="row" pb={[2, 4]} mt={4} justifyContent="center" width={["100%", 400]}>
                <Box display="flex" width="100%" flexDirection="column" flex={1} pr={[0, 5]}>
                  <Typography variant="h4" color="textPrimary">
                    <Trans>TRANSFERENCIA BANCARIA</Trans>
                  </Typography>
                  <Divider className={classes.divider} orientation="horizontal" flexItem />
                  <Box display="flex" flexDirection="row" alignItems="center" mt={2} mb={2}>
                    <Typography variant="subtitle1" color="textPrimary">
                      <Trans>
                        Por favor, adjunte el justificante de transferencia por el importe total de la reserva
                      </Trans>
                    </Typography>
                  </Box>
                  <Box mt={2} className={classes.uploadPage}>
                    <Box mt={2} className={classes.uploadSection}>
                      {(uploadStatus === "initial" || uploadStatus === "error") && (
                        <label htmlFor={uploadId}>
                          <FileUpload
                            id={uploadId}
                            name={uploadId}
                            maxSize={MAX_MB_ATTACHMENTS * 1024 * 1024} // 30MB
                            accept="image/jpg,image/jpeg,image/png,application/pdf"
                            style={{ display: "none" }}
                            onStart={(file) => {
                              setFileName(removeAWSSpecialCharacters(file.name));
                              setUploadStatus("loading");
                            }}
                            onComplete={handleUploadComplete}
                            onError={() => {
                              setUploadStatus("error");
                            }}
                          />

                          <Box className={classes.customButton}>
                            <Typography variant="button">
                              <Trans>Subir comprobante de pago</Trans>
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: "rgba(0, 0, 0, 0.26)",
                                textAlign: "center",
                              }}
                            >
                              <Trans>Max.</Trans> {MAX_MB_ATTACHMENTS}MB
                            </Typography>
                          </Box>
                        </label>
                      )}
                      {uploadStatus === "loading" || payStatus === "loading" ? (
                        <LoadingBar>
                          <Trans>Subiendo comprobante....</Trans>
                        </LoadingBar>
                      ) : (
                        <></>
                      )}
                      {uploadStatus === "success" && (
                        <a
                          href={decodeURI(uploadedFile.url)}
                          target="_blank"
                          className={classes.fileLink}
                          rel="noreferrer"
                        >
                          <Box className={classes.uploadSuccessFile}>
                            <Tooltip
                              arrow
                              title={
                                <Typography style={{ textAlign: "center" }} variant="body1">
                                  {decodeURI(fileName)}
                                </Typography>
                              }
                              leaveDelay={1000}
                            >
                              <Typography variant="body1" className={classes.fileName}>
                                {decodeURI(fileName)}
                              </Typography>
                            </Tooltip>
                            <DownloadIcon
                              style={{
                                marginRight: theme.spacing(1 / 2),
                              }}
                            />
                            <TrashIcon onClick={handleRemoveUploadedFile} />
                          </Box>
                        </a>
                      )}
                    </Box>
                  </Box>

                  {uploadStatus === "success" && (
                    <Button
                      onClick={() => {
                        handlePayment();
                        setUploadStatus("finished");
                      }}
                      variant="contained"
                      color="primary"
                      className={classes.confirmButton}
                    >
                      <Trans>CONFIRMAR</Trans>
                    </Button>
                  )}
                </Box>
              </Box>
            ) : (
              <OnePageMessage
                title={`${i18n._(t`Hubo un inconveniente`)}`}
                desc={`${i18n._(t`No te preocupes, puedes volver a adjuntar el comprobante.`)}`}
                buttonName={`${i18n._(t`Volver`)}`}
                action={handleBack}
              />
            ))}
          {payStatus === "success" || invoice?.payment ? (
            <>
              <OnePageMessage
                title={`${i18n._(t`EL PAGO SE HA REALIZADO CON ÉXITO`)}`}
                desc={`${i18n._(t`¡Enhorabuena! Ya tienes el espacio perfecto para tu proyecto`)}`}
                buttonName={`${i18n._(t`VER RESERVA`)}`}
                action={onFinish}
              />
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} mt={5} mb={5}>
          <TenantBookingInvoiesModal />
          <SpaceInfoPaper showOnlyInfo invoice={invoice} />
        </Box>
      </Container>
    </>
  );
}
