import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  bookingPickerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: "12px",
    minWidth: "160px",
  },
  menuButton: {
    width: "100%",
    padding: theme.spacing(1.5, 2),
    fontWeight: "bold",
    textTransform: "none",
    borderRadius: "8px",
    clipPath: "polygon(0 0, 100% 0, 90% 100%, 0% 100%)",
    transition: "background 0.3s, transform 0.2s",
    "&:hover": {
      transform: "translateX(4px)",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  inactiveButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.grey[400]}`,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export default function BookingStateMenu({ BookingState, OnChangeBookingState }) {
  const { i18n } = useLingui();
  const classes = useStyles({ ...theme });

  const bookingStatus = {
    inprogress: i18n._(t`Consultas`),
    "to-be-started": i18n._(t`Reservas por comenzar`),
    active: i18n._(t`Reservas activas`),
    done: i18n._(t`Reservas finalizadas`),
    cancelled: i18n._(t`Canceladas`),
  };

  return (
    <Box className={classes.bookingPickerContainer}>
      {Object.keys(bookingStatus).map((key) => (
        <Button
          key={key}
          size="small"
          className={`${classes.menuButton} ${BookingState === key ? classes.activeButton : classes.inactiveButton}`}
          onClick={() => OnChangeBookingState({ target: { value: key } })}
        >
          <Typography variant="subtitle1">{bookingStatus[key]}</Typography>
        </Button>
      ))}
    </Box>
  );
}
