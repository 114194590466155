import React, { useEffect } from "react";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import BrandDashboard from "/src/containers/brand-dashboard";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import config from "/src/config.js";
import SEO from "/src/components/seo";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentAsset } from "../../redux/actions/misc-actions";
import { useNavigate } from "/src/hooks/navigation";
import SetupContainer from "../../setup-container";

const TenantPage = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigate();
  const isAdmin = useSelector((state) => state.user.data.isAdmin);
  const bookingId = useSelector((state) => state.consultation.data.bookingId);

  useEffect(() => {
    if (isAdmin) {
      navigate(`/administrator/requests/${bookingId}`);
    }
  }, [isAdmin, bookingId]);

  dispatch(setCurrentAsset());
  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Tenant`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <BrandDashboard />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default TenantPage;
